@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  /* font-family: 'Poppins', sans-serif; */
  /* font-family: 'Lato', sans-serif; */
  /* font-family: 'Montserrat', sans-serif; */
  font-family: 'DM Sans', sans-serif;
  /* font-family: 'Nunito', sans-serif; */
}

.header {
  background-image: linear-gradient(110deg, rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, 0) 90%), url('./assets/images/caught-in-joy-ptVBlniJi50-unsplash.jpg');
  background-position: right;
  background-size: 100%;
}

.wrapper::-webkit-scrollbar {
  height: 0;
}

.pannel::-webkit-scrollbar {
  height: 0;
}

.img {
  background-position: center;
  background-size: 70%;
  background-repeat: no-repeat;
  transition: all 0.1s;
}

.carousel button {
  transition: 0.3s;
}

.carousel button {
  opacity: 0;
}

.carousel:hover button {
  opacity: 100;
}

.about {
  min-height: calc(100vh - 500px);
}

.about__img {
  height: calc(100vh - 216px);
}

.aside__navbar {
  height: calc(100vh - 64px);
}

.slider {
  background: none;
  outline: none;
  pointer-events: none;
}

.slider::-webkit-slider-thumb {
  pointer-events: auto;
  appearance: none;
  width: 17px;
  height: 17px;
  background-color: white;
  border: 2px solid red;
  border-radius: 50%;
}

/* Home Category */

.bg {
  background-position: center;
  background-size: 180%;
  position: relative;
  transition: 0.2s;
}

.bg::after {
  content: '';
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4);
  transition: 0.2s;
  border-radius: 10px;
}

@media screen and (min-width: 1024px) {
  .bg:hover {
    background-size: 170%;
  }
  
  .bg:hover::after {
    content: '';
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.8);
  }
}




.banner {
  background: linear-gradient(to right, rgb(24, 24, 27, 1), rgb(24, 24, 27, 0.5), rgb(24, 24, 27, 1) 100%), url('https://images.unsplash.com/photo-1633933769681-dc8d28bdeb6d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80');
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
}

@media screen and (max-width: 820px) {
  .header {
    background-image: linear-gradient(120deg, rgba(0, 0, 0, 1) 40%, rgba(0, 0, 0, 0) 80%), url('./assets/images/caught-in-joy-ptVBlniJi50-unsplash.jpg');
    background-size: cover;
  }
}

@media screen and (min-width: 1024px) {
  .card:hover .img {
    padding: 10px;
  }

  .card:hover .title {
    color: red;
  }
}